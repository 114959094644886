import React, { useState } from "react"
import { Hero } from "../components/Hero/Hero"
import SEO from "../components/SEO"
import IconArrow from '../components/IconArrow'
import IconArrowRight from '../components/IconArrowRight'
import heroImage from '../images/hero/why.jpg'
import '../styles.scss'

const WhyPage = (props) => {
    const [activeTestimonial, setActiveTestimonial] = useState(0)
    const [testimonials, setTestimonials] = useState([
    {
      heading: 'I write this recommendation in support of Keith Sammels, one of the best and most accountable creative influences that I have had the pleasure of working with.',
      content: [
        'Keith is the ultimate professional, always looking to the future and what that means to you as a client.  Unusually, Keith possesses a level of rigour and commitment towards getting results that I rarely see elsewhere, backed up with clear analysis and data to demonstrate the outstanding returns on investment that we enjoyed when I worked with Keith and the team at LAW Creative during my time with InterContinental Hotels Group Ltd.',
        'Having the correct team with you when marketing investment has to be right is absolutely essential.  I consider myself fortunate to have worked with Keith.  His creativity and spark constantly lifted our marketing to new heights and even with the constraints of brand guidelines, Keith was always able to express what we needed in unique ways, that were ultimately adopted by the brands.  I have no doubt that Montana Concept will benefit those who are fortunate enough to work with Keith.'
      ],
      author: 'David Taylor',
      position: 'CEO & President of Management Board at Adriatic Luxury Hotels',
      isActive: true
    },
    {
      heading: 'I have always associated Nicola with exceptional high quality work.  She has the ability to identify channels to amplify communications and I applaud her excellent attention to detail.',
      content: [
        'A \'rockstar\' who’s dedication to the task assisted us in achieving award-winning successful integrated campaigns during my time at InterContinental Hotels Group where I was Corporate Brand Director.'
      ],
      author: 'Geraldine Connell',
      position: 'Brand, Customer & Strategic Marketing Expert',
      isActive: false
    },
    {
      heading: 'Keith’s contribution to these highly successful Gov UK and UH supported schemes had been significant.',
      content: [
        'Keith Sammels has been a Mentor at University of Hertfordshire for some two years. His work has primarily involved business support schemes such as Help to Grow, Herts Camera Action and Build Back Better. Keith has substantial experience in developing, selling and buying businesses in the marketing services sector and his contribution to these highly successful Gov UK and UH supported schemes had been impactful. Keith has enjoyed several mentee accolades and we at UH have greatly enjoyed having him on the team.'
      ],
      author: 'Sareh Solati',
      position: 'Operations Officer | Business Growth & Entrepreneurship, University of Hertfordshire',
      isActive: false
    }
  ])

  const handleNavigateTestimonials = (activeIndex) => {
    if(activeIndex > -1 && activeIndex < testimonials.length) {
      setActiveTestimonial(activeIndex)
      setTestimonials(
        testimonials.map((testimonial, index) => {
          if(index === activeIndex) { testimonial.isActive = true }
          else {  testimonial.isActive = false }

          return testimonial
        })
      )
    }
  }

  return (
    <>
      <SEO title="Why" />
        <Hero backgroundImage={heroImage}>
        <div className='container height-100 mob-100'>
          <div className='container-blue-90 content-70 height-100'>
            <button
              className='vertical-center'
              style={{right:'-.5rem'}}
              onClick={() => handleNavigateTestimonials(activeTestimonial + 1)}
            >
              <IconArrow />
            </button>
            <button
              className='vertical-center'
              style={{left:'-.5rem'}}
              onClick={() => handleNavigateTestimonials(activeTestimonial - 1)}
            >
              <IconArrowRight />
            </button>
            {testimonials.map(({ heading, content, author, position, isActive }, index) => (
              <div className={`testimonial ${isActive && 'active'}`} key={index}>
                <blockquote>
                  <p className='heading'>{heading}</p>
                  {content.map((text, index) => <p key={index}>{text}</p>)}
                </blockquote>
                <p className='author'>{author}</p>
                <p className='author-position'>{position}</p>
              </div>
            ))}
          </div>
        </div>
      </Hero>
      </>
  )
}

export default WhyPage